<template>
  <div>
    <t-modal
      :name="mid"
      :variant="`transaction`"
      :hideCloseButton="true"
      :disableBodyScroll="true"
      ref="popup"
    >
      <section class="px-4">
        <oto-typography
          :text="$t('components.refundPopup.title')"
          variant="one"
          class="block pl-2 mt-8 w-full"
        />

        <transaction-card
          :amount="form.amount"
          :currency-symbol="getCurrencySymbol"
          class="mb-8"
        />

        <text-input
          :name="$t('components.refundPopup.refundTo')"
          :label="$t('components.refundPopup.refundTo')"
          :disabled="true"
          :value="getUserFullname"
        />
        <div class="flex justify-items-start items-center pb-5">
          <label class="cursor-pointer mr-4 flex items-center">
            <TRadioUnwrapped
              v-model="refund_amount_type"
              :value="`full`"
              :name="`refund_amount_type`"
            />

            <span class="ml-3 font-medium text-14px text-oDark">
              {{ $t('components.refundPopup.fullRefund') }}
            </span>
          </label>
          <label class="cursor-pointer flex items-center">
            <TRadioUnwrapped
              v-model="refund_amount_type"
              :value="`partial`"
              :name="`refund_amount_type`"
            />

            <span class="ml-3 font-medium text-14px text-oDark">
              {{ $t('components.refundPopup.partialRefund') }}
            </span>
          </label>
        </div>

        <AppInput
          v-model="form.amount"
          type="number"
          step="0.01"
          :name="
            $t('components.refundPopup.amount', {
              currencySymbol: getCurrencySymbol,
            })
          "
          :label="
            $t('components.refundPopup.amount', {
              currencySymbol: getCurrencySymbol,
            })
          "
          :isInfoEnabled="false"
          :disabled="refund_amount_type === 'full' ? true : false"
        />

        <AppInput
          :name="$t('components.refundPopup.paymentSource')"
          :label="$t('components.refundPopup.paymentSource')"
          :isInfoEnabled="false"
          type="richselect"
          placeholder="Payment source"
          v-model="form.target"
          :options="[
            { text: 'eWallet', value: 'eWallet' },
            { text: 'Card', value: 'card' },
          ]"
          :hide-search-box="true"
          :disabled="getSourceDisabled"
        />

        <text-input
          :name="$t('components.refundPopup.remarks')"
          :label="$t('components.refundPopup.remarks')"
          type="textarea"
          input_type="textarea"
          :placeholder="$t('components.refundPopup.notePlaceholder')"
          v-model="form.remarks"
        />

        <oto-typography
          :text="message"
          variant="three"
          class="block w-full h-6 text-center text-oRed"
        />

        <div
          class="flex gap-4 justify-end items-center py-4 px-4"
          v-if="!refund.is_refunded"
        >
          <AppButton
            :text="$t('components.stepNavigation.cancel')"
            variant="secondary"
            @click="$refs.popup.hide()"
          />

          <AppButton
            :is-loading="isLoading"
            :text="$t('components.paymentManagement.actions.refund')"
            variant="orange"
            @click="submitForm"
          />
        </div>
      </section>
    </t-modal>
  </div>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { TransactionConfig } from '@/config/TransactionConfig'
import TransactionCard from '@/components/cards/TransactionCard.vue'
// import CostMessageBox from "@/components/user-management/trips/CostMessageBox.vue";
// import TransactionList from "@/components/transactions/TransactionList.vue";

import TextInput from '@/components/form/TextInput.vue'
import OtoTypography from '@/components/ui/OtoTypography.vue'
// import OtoTypography from "@/components/ui/OtoTypography.vue";

export default {
  name: 'RefundDebitPopup',
  components: {
    TextInput,
    // OtoTypography,
    TransactionCard,
    OtoTypography,
    // CostMessageBox,
    // TransactionList
  },

  props: {
    paymentSourceEnabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      mid: TransactionConfig.events.refundPopup,
      isLoading: false,
      message: '',
      refund_amount_type: 'full',
      form: {
        remarks: '',
        target: '',
        refund_card_payment_to_wallet: true,
        amount: 0,
      },
      refund: {},

      paymentMethod: '',
    }
  },
  computed: {
    getUserFullname() {
      return this.refund && this.refund.user ? this.refund.user.full_name : ''
    },
    getCurrencySymbol() {
      return this.refund?.original_currency?.symbol || '--'
    },
    getSourceDisabled() {
      if (this.paymentMethod !== 'card') {
        return true
      } else {
        return false
      }
    },
  },
  mounted() {
    EventBus.$on(TransactionConfig.events.refundPopup, (item) => {
      console.log('refund-pop', item)
      this.refund = item
      this.message = ''
      this.paymentMethod =
        this.refund.payment_method === 'eWallet' ? 'eWallet' : 'card'

      this.form = {
        remarks: '',
        target: this.paymentMethod === 'card' ? 'card' : 'eWallet',
        amount: this.refund?.original_amount || 0,
        refund_card_payment_to_wallet: true,
      }
    })
  },
  methods: {
    submitForm() {
      this.isLoading = true

      let formData = new FormData()
      formData.append('remarks', this.form.remarks)

      if (this.refund_amount_type === 'partial') {
        formData.append('amount', this.form.amount)
      }
      if (this.form.target === 'eWallet' && this.paymentMethod === 'card') {
        formData.append(
          'refund_card_payment_to_wallet',
          this.form.refund_card_payment_to_wallet
        )
      }

      this.$http
        .post(TransactionConfig.api.refund(this.refund.id), formData)
        .then((res) => {
          this.$emit('refunded', res.data)
          this.$refs.popup.hide()
        })
        .catch(({ response }) => {
          console.warn(response)
          this.message =
            (response.data.detail || 'Failed to refund!') +
            ' [Error Code: ' +
            response.status +
            ']'
          this.$emit('failed', response)

          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: 'Error occuered!',
            text: this.message,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.popup-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
</style>
